import { Component, OnInit, ViewChild } from '@angular/core';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.css'],
})
export class RouteComponent implements OnInit {
  @ViewChild('arrow') arrow;
  public resizeTimer;

  public constructor(public scrollServ: ScrollService) {}

  ngOnInit() {}

  public scrollDown() {
    if (this.scrollServ.currentPage == 4) {
      for (let i = 3; i > 0; i--) {
        this.scrollServ.reverseAnimation();
      }
    } else {
      this.scrollServ.scrollAnimation();
    }
  }

  public stopAnimation() {
    const classes = document.body.classList;
    let timer = 0;

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        else
            classes.add('animation-stopper');

    timer = setTimeout(() => {
      classes.remove('animation-stopper');
      timer = null;
    }, 100);

  }
}
