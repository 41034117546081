import { Component, OnInit } from '@angular/core';
import { Scroll } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css'],
})
export class DownloadComponent implements OnInit {
  isOpen = false;
  constructor() {}

  ngOnInit(): void { }

  openSlider() {
    this.isOpen = true;
    document.getElementById('info-slider').classList.remove('information-slider');
    document.getElementById('info-slider').classList.add('openSlider');
    if (window.innerWidth < 900) {
      document.getElementById('download-nav').style.display ="none"
    }
  }

  closeSlider() {
    this.isOpen = false
    document.getElementById('info-slider').classList.remove('openSlider');
    document.getElementById('info-slider').classList.add('information-slider');
    document.getElementById('download-nav').style.display ="block"
  }

  hideNav() {
    if (this.isOpen == true) {
      if (window.innerWidth < 900) {
        document.getElementById('download-nav').style.display = "none";
      } else {
        document.getElementById('download-nav').style.display = "block";
      }
    }
    return;
  }

}
