import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-static-title',
  templateUrl: './static-title.component.html',
  styleUrls: ['./static-title.component.css'],
})
export class StaticTitleComponent implements AfterViewInit {
  @Input() action: boolean;
  @ViewChild('underline1') underline1;
  @ViewChild('underline2') underline2;
  @ViewChild('stopTitle') stopTitle;
  constructor() {}

  ngAfterViewInit(): void {
    if (this.action) {
      this.stopTitle.nativeElement.classList.remove('static-title');
      this.stopTitle.nativeElement.classList.add('action-title');
      this.underline1.nativeElement.style.borderColor = '#fedc48';
      this.underline2.nativeElement.style.borderColor = '#fedc48';
    }
  }

  public toLanding(): void {
    var title = document.getElementById('stop-title');
    title.classList.remove('toManifesto');
    title.classList.add('static-title');
  }

  public toManifesto(): void {
    var title = document.getElementById('stop-title');
    title.classList.remove('static-title');
    title.classList.add('toManifesto');
  }
}
