import { Component, AfterViewInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements AfterViewInit {
  @ViewChild('gallery') gallery;
  public currentImg: number = 0;

  constructor() {}

  ngAfterViewInit(): void {
  }

  public switchImage(direction: string): void {
    if (direction == 'right') {
      if (this.currentImg >= this.gallery.nativeElement.childNodes.length - 1)
        return;
      this.gallery.nativeElement.childNodes[this.currentImg].style.marginLeft =
        '-100%';
      this.gallery.nativeElement.childNodes[
        this.currentImg + 1
      ].style.marginLeft = '0%';
      this.currentImg++;
    } else {
      if (this.currentImg == 0) return;

      this.gallery.nativeElement.childNodes[this.currentImg].style.marginLeft =
        '100%';
      this.gallery.nativeElement.childNodes[
        this.currentImg - 1
      ].style.marginLeft = '0%';

      this.currentImg--;
    }
  }
}
