<div (window:resize)="this.resize()" class="nav-container">
  <div #nav id="nav" class="nav-column">
    <p
      (click)="this.scrollServ.navAction(2)"
      name="manifesto"
      id="mani-nav"
      class="nav-item"
    >
      MANIFESTO
    </p>
    <p
      (click)="this.scrollServ.navAction(3)"
      name="action"
      id="act-nav"
      class="nav-item"
    >
      ACTION
    </p>
    <p
      (click)="this.scrollServ.navAction(4)"
      name="download"
      id="down-nav"
      class="nav-item"
    >
      DOWNLOAD
    </p>
  </div>
</div>
