import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-title',
  templateUrl: './main-title.component.html',
  styleUrls: ['./main-title.component.css'],
})
export class MainTitleComponent implements OnInit {
  public landing;

  constructor() {}

  ngOnInit(): void {}

  toLanding(): void {
    document.getElementById('landing').classList.remove('toManifesto');
    document.getElementById('landing').classList.add('main-title-container');
  }

  toManifesto(): void {
    document.getElementById('landing').classList.remove('main-title-container');
    document.getElementById('landing').classList.add('toManifesto');
  }
}
