import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.css'],
})
export class QueryComponent implements OnInit {
  @Input() public query: String;
  @Input() public active: boolean;
  public url: string;

  constructor() {}

  ngOnInit(): void {
    this.url =
      'https://www.google.com/search?q=' +
      this.query.toLowerCase().replace(/ /g, '+');
  }
}
