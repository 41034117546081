import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manifesto',
  templateUrl: './manifesto.component.html',
  styleUrls: ['./manifesto.component.css'],
})
export class ManifestoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public toManifesto() {
    var mani = document.getElementById('manifesto');
    mani.classList.remove('manifesto-container');
    mani.classList.add('toManifesto');

    var maniRight = document.getElementById('mani-right');
    maniRight.classList.remove('mani-right');
    maniRight.classList.add('toManiRight');
    if (window.innerWidth > 900) {
      document.getElementById('sub-padding').style.marginTop = "50px";
    } else {
      document.getElementById('sub-padding').style.marginTop = "30px";
    }
    }

  public mobileManifesto() {
    var maniRight = document.getElementById('mani-right');
    var maniLeft = document.getElementById('mani-left');
    maniRight.classList.remove('toManiRight');
    maniRight.classList.add('maniRightAni');
    maniLeft.classList.add('maniLeftAni');
    maniLeft.style.opacity = '0';
    maniLeft.style.height = '0px';
  }

  public reverseMobile() {
    var maniRight = document.getElementById('mani-right');
    var maniLeft = document.getElementById('mani-left');
    maniLeft.classList.remove('maniLeftAni');
    maniLeft.style.opacity = '1';
    maniLeft.style.height = '800px';
    maniRight.classList.remove('maniRightAni');
    maniRight.classList.add('toManiRight');
  }

  public toLanding() {
    var maniRight = document.getElementById('mani-right');
    var maniLeft = document.getElementById('mani-left');
    var mani = document.getElementById('manifesto');
    mani.classList.remove('toManifesto');
    mani.classList.add('manifesto-container');
  }

  public resize() {
    var maniLeft = document.getElementById('mani-left');
    var maniRight = document.getElementById('mani-right');
    if (window.innerWidth > 900) {
      maniRight.classList.remove('maniRightAni');
      maniRight.classList.add('toManiRight');
      maniLeft.style.height = 'fit-content';
    }
    maniLeft.style.opacity = '1';
  }
}
