<div id="overlay"></div>

<div class="not-found">
  <div class="left">
    <div class="error-container">
      <p class="not-found-title">4</p>
      <p class="no-underline-title">0</p>
      <p class="not-found-title">4</p>
    </div>
  </div>
  <div class="right">
    <p class="error-text">
      SOMETHING'S NOT RIGHT HERE
    </p><br>
    <p class="error-text smaller">
      In every age group, White British households were more likely to be homeowners than all ethnic minority households combined. (English Housing Survey 2017 to 2018)
    </p><br>
    <p class="error-text smaller">
      RETURN TO THE
      <a class="homepage" href="/">HOMEPAGE</a>AND DON'T STOP SEARCHING.
    </p>
    <div class="search-links">
      <p
        class="links"
        *ngFor="let query of this.queryList"
        (click)="this.goto(query)"
      >
        {{ query }}
      </p>
    </div>
  </div>
</div>
