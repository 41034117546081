<div (window:resize)="hideNav()" id="dwn-container" class="download-container">
  <div id="info-slider" class="information-slider">

    <img class="exit-icon" (click)="closeSlider()" src="../../assets/images/cross.svg"/>


    <div class="info-container">
      <div class="info-left">
        <p class="left-title">YOUR ACTIONS MAKE A DIFFERENCE. FOR FURTHER INSIGHT, TO DONTATE OR TO TAKE ACTION IN YOUR COMMUNITY, CHECK OUT THESE RESOURCES.</p>
        <p class="moreinfo">FOR MORE INFORMATION OR TO OFFER FEEDBACK, <a class="contact-link" href="mailto:info@stopsearch.uk">CONTACT US.</a></p>
      </div>
      <div class="info-right">
        <div class="links-container">
        <p class="subtitle">LEARN MORE</p>
        <div class="links">
          <a href="https://www.youtube.com/channel/UCtJ9fObTQzhb01i2s5C_fpQ" target="_blank"
          ><p>AKALA'S YOUTUBE CHANNEL</p>,</a>
          <a href="https://www.aboutracepodcast.com/" target="_blank"
            ><p>ABOUT RACE PODCAST</p>,</a>
          <a href="https://www.instagram.com/galdemzine/" target="_blank">
            <p>GAL DEM</p>,</a>
        </div>
        <div class="links">
          <a href="http://laylafsaad.com/meandwhitesupremacy" target="_blank"
          ><p>LAYLA F.SAAD: ME AND WHITE SUPREMACY</p>,</a>
          <a href="https://www.npr.org/sections/codeswitch?t=1601472721890" target="_blank"
            ><p>CODE SWITCH PODCAST</p>,</a>
        </div>
        <div class="links">
          <a href="https://www.bbc.co.uk/programmes/p0499smp" target="_blank"
          ><p>BLACK TO LIFE: RETHINKING THE BLACK<br class="break-line"> PRESENCE WITHIN BRITISH HISTORY</p>,</a>
        </div>
        <div class="links">
          <a href="https://twitter.com/SholaMos1" target="_blank"
          ><p>DR. SHOLA MOS-SHOGBAMIMU ON TWITTER</p></a>
        </div>
      </div>
      <div class="links-container">
          <p class="subtitle">KNOW YOUR RIGHTS</p>
          <div class="links link-column">
            <a href="https://www.london.gov.uk/sites/default/files/kyr_inner_april_19_v5_new.pdf" target="_blank"
            ><p>YOUR RIGHTS DURING A POLICE STOP AND SEARCH</p></a>
            <a href="https://www.citizensadvice.org.uk/health/discrimination-in-health-and-care-services/" target="_blank"
            ><p>UNDERSTANDING HEALTHCARE DISCRIMINATION</p></a>
            <a href="https://www.citizensadvice.org.uk/family/education/discrimination-in-education/taking-action-about-discrimination-in-education/" target="_blank"
            ><p>REPORTING EDUCATION DISCRIMINATION</p></a>
          </div>
          </div>
          <div class="links-container">
          <p class="subtitle">TAKE ACTION</p>
          <div class="links">
            <a href="https://www.stop-watch.org/" target="_blank"
            ><p>WWW.STOP-WATCH.ORG</p>,</a>
            <a href="https://www.runnymedetrust.org/" target="_blank"
              ><p>WWW.RUNNYMEDETRUST.ORG</p>,</a>
          </div>
          <div class="links">
            <a href="https://raceequalityfirst.org/" target="_blank"
            ><p>WWW.RACEEQUALITYFIRST.ORG</p>,</a>
            <a href="https://blacklivesmatter.com/" target="_blank"
              ><p>WWW.BLACKLIVESMATTER.COM</p></a>
          </div>
          <div class="links">
            <a href="https://blkmrkt.co.uk/" target="_blank"
            ><p>WWW.BLKMRKT.CO.UK</p></a>
          </div>
        </div>
      </div>
    </div>

  </div>
  <p class="download-text">
    SHARE THESE POSTERS ON SOCIAL<br> MEDIA, AROUND YOUR NEIGHBOURHOOD<br> OR IN YOUR WINDOW.
  </p>

  <p class="warning-text">
    PLEASE DO NOT PUT POSTERS AND STICKERS UP WITHOUT CONSENT, ON PERSONAL BELONGINGS, PRIVATE PROPERTY OR ANYWHERE THAT CAN RESULT IN DAMAGE.
  </p>

  <div class="dwn-buttons">
    <a href="../../assets/STOP-SEARCH-POSTER.pdf" target="_blank">
      <div class="download-button">
        <div
          class="down-icon"
          alt="Download Posters"
          src="../../assets/images/download.svg"
        ></div>
        <p class="dwn-text">DOWNLOAD POSTERS</p>
      </div>
    </a>
    <a href="../../assets/STOP-SEARCH-SOCIAL-ASSETS.pdf" target="_blank">
      <div class="dwn-text" class="download-button">
        <div
          class="down-icon"
          alt="Download Posters"
          src="../../assets/images/download.svg"
        ></div>
        <p class="dwn-text">DOWNLOAD SOCIAL ASSETS</p>
      </div>
    </a>
    <a href="../../assets/STOP-SEARCH-STICKER.pdf" target="_blank">
      <div class="download-button">
        <div
          class="down-icon"
          alt="Download Posters"
          src="../../assets/images/download.svg"
        ></div>
        <p class="dwn-text">DOWNLOAD STICKERS</p>
      </div>
    </a>
  </div>

  <div class="social-icons">
    <a href="https://twitter.com/intent/tweet?text='Four times more likely’, ‘slavery tax’, ‘health bias UK'. I'm asking you to search these terms and more to uncover the UK's systemic racism. https://stopsearch.uk/" target="_blank" class="social-item">TW</a>
    <a href="https://www.facebook.com/sharer/sharer.php?u=https://stopsearch.uk/" target="_blank" class="social-item" id='twitter'>FB</a>
  </div>

  <div id="footerLinks" class="footer-container">


    <div class="footer-links">
      <a (click)="openSlider()" id="learn" target="_blank"
        ><p>EXPLORE RESOURCES</p></a
      >
      <a href="mailto:info@stopsearch.uk" id="contact" target="_blank"
      ><p>CONTACT US</p></a
      >
    </div>
  </div>
</div>


