<div class="list-container">
  <div
    #queries
    id="query-list"
    name="queryList"
    (scroll)="this.scrollManager(this.queries)"
    class="query-list"
  >
    <div class="filler-top"></div>
    <app-query
      *ngFor="let query of this.queryList"
      query="{{ query }}"
      name="query"
    ></app-query>
    <div class="filler"></div>
  </div>
</div>
