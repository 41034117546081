import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
  public queryList: Array<string> = [
    'SECTION 60 LOCKDOWN',
    'FOUR TIMES MORE LIKELY',
    'APRIL 22 ELTHAM',
    'GO HOME VANS',
    '72 VICTIMS',
    'SLAVERY TAX',
    'INSPECTOR EHIKIOYA',
    'GANGS MATRIX',
    'DARREL AND DARREN',
    'ESN SCHOOLS UK',
    'LESS THAN 1% OF PROFESSORS',
    'REFERENDUM SPIKE',
    'BRITAIN WEALTH COVID',
    '21.7% PAY GAP',
    'HEALTH BIAS UK',
  ];
  constructor() {}

  ngOnInit(): void {}

  public goto(query): void {
    var url =
      'https://www.google.com/search?q=' +
      query.toLowerCase().replace(/ /g, '+');
    window.open(url);
  }
}
