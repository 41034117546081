import { Injectable, OnInit, ViewChild } from '@angular/core';
import { MainTitleComponent } from './main-title/main-title.component';
import { StaticTitleComponent } from './static-title/static-title.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ManifestoComponent } from './manifesto/manifesto.component';
import { QueryListComponent } from './query-list/query-list.component';
import { ConstantPool } from '@angular/compiler';

@Injectable({ providedIn: 'root' })
export class ScrollService implements OnInit {
  public scrollServ: ScrollService;
  public currentPage: number = 0;
  public scrollUp: number = 0;
  public scrollDown: number = 0;
  public direction: boolean;
  public timerId;
  public xDown = null;
  public yDown = null;
  public maniStage: number = 0;
  public scrollHandler: number = 0;
  public timer;

  public arrowSrc: string = '../../assets/images/arrow.svg';

  @ViewChild('topSection') topSection;
  public mainTitle: MainTitleComponent = new MainTitleComponent();
  public stopTitle: StaticTitleComponent = new StaticTitleComponent();
  public navBar: NavBarComponent = new NavBarComponent(this.scrollServ);
  public manifesto: ManifestoComponent = new ManifestoComponent();
  public queryList: QueryListComponent = new QueryListComponent(
    this.scrollServ
  );

  ngOnInit(): void {}

  public getTouches(evt) {
    if (this.currentPage == 3) return;
    if (this.currentPage == 4) return;
    return evt.touches;
  }

  public handleTouchStart(evt) {
    if (this.currentPage == 3) return;
    if (this.currentPage == 4) return;
    const firstTouch = this.getTouches(evt)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  }

  public handleTouchMove(evt) {
    if (this.currentPage == 3) return;
    if (this.currentPage == 4) return;
    if (!this.xDown || !this.yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.xDown - xUp;
    var yDiff = this.yDown - yUp;

    if (yDiff > 0) {
      this.scrollAnimation();
    } else {
      this.reverseAnimation();
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
  }

  public navAction(target) {
    if (this.currentPage == target) return;
    if ((this.currentPage == 0) && (target == 2)) {
      target = 1;
    }

    if (target > this.currentPage) {
      while (this.currentPage < target) {
        this.scrollAnimation();
      }
    } else {
      while( this.currentPage != target){
        this.reverseAnimation();
      }
    }
  }

  public scrollAction(event): void {
    var isMobile = navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );


    if (isMobile) {
      if (this.currentPage == 3) return;
      if (this.currentPage == 4) return;
      return;
    }

    if (window.innerWidth < 900) {
      if (this.currentPage == 3) return;
      if (this.currentPage == 4) return;
    }


    if (event.deltaY < 0) {
      if (this.currentPage == 0) return;
      this.scrollUp++;
      if (this.scrollUp > 90) {
        this.scrollUp = 0;
        this.scrollDown = 0;
        this.reverseAnimation();
      }
    } else if (event.deltaY > 0) {
      if (this.currentPage == 4) return;
      this.scrollDown++;
      if (this.scrollDown > 50) {
        this.scrollUp = 0;
        this.scrollDown = 0;
        this.scrollAnimation();
      }
    }
  }

  public reverseAnimation() {
    clearTimeout(this.timer);
    switch (this.currentPage) {
      case 1:
        this.landingAni();
        break;
      case 2:
        this.reverseManifesto();
        break;
      case 3:
        this.reverseAction();
        break;
      case 4:
        this.reverseDownload();
        break;
      default:
        break;
    }
  }

  public scrollAnimation() {
    clearTimeout(this.timer);
    if (window.innerWidth < 900) {

      if (this.currentPage == 4) return;
    }

    this.currentPage++;

    switch (this.currentPage) {
      case 1:
        this.manifestoAni();
        break;
      case 2:
        this.manifestoStageTwo();
        break;
      case 3:
        this.actionAni();
        break;
      case 4:
        this.downloadAni();
        break;
      default:
        break;
    }
  }

  public landingAni(): void {
    this.mainTitle.toLanding();
    this.stopTitle.toLanding();
    this.navBar.resetNav('manifesto');
    this.manifesto.toLanding();
    this.currentPage = 0;
  }

  public manifestoAni(): void {
    this.mainTitle.toManifesto(); //Animate main title
    this.stopTitle.toManifesto();
    this.navBar.animateNav('manifesto');
    this.manifesto.toManifesto();
    this.currentPage = 1;
  }

  public manifestoStageTwo(): void {
    if (window.innerWidth < 900) {
      this.manifesto.mobileManifesto();
      this.currentPage = 2;
    } else {
      this.actionAni();
    }
  }

  public reverseManifesto(): void {
    if (window.innerWidth < 900) {
      this.manifesto.reverseMobile();
      this.currentPage = 1;
    } else { this.landingAni();}
  }

  public reverseAction(): void {
    document.getElementById('topSection').style.marginBottom = '0vh';
    document.getElementById('topSection').style.transition = '1.5s';
    document.getElementById('topSection').style.height = '100vh';

    this.resetQueries();

    this.arrowSrc = "../../assets/images/arrow.svg"
    this.navBar.resetNav('action');
    this.navBar.animateNav('manifesto');
    //Mobile animation
    this.currentPage = 2;
  }

  public actionAni(): void {
    document.getElementById('topSection').style.transition = '1.5s';
    document.getElementById('topSection').style.height = '0vh';
    this.arrowSrc = "../assets/images/arrow-yellow.svg"

    this.navBar.resetNav('manifesto');
    this.navBar.animateNav('action');

    this.timer = setTimeout(function () {
      document.getElementById('topSection').style.transition = 'none';
      document.getElementById('topSection').style.marginBottom = '100vh';
    }, 1500);

    this.currentPage = 3;
  }

  public reverseDownload(): void {
    document.getElementById('downloadSection').style.transition = '1.5s';
    document.getElementById('downloadSection').style.marginTop = '100vh';
    document.getElementById('action-nav').style.height = "100%";
    document.getElementById('dwn-container').style.marginTop = '15%';
    document.getElementById('download-nav').style.backgroundColor =
      'transparent';


    document.getElementById('download-nav').style.paddingBottom = '0';

    this.navBar.resetNav('download');
    this.navBar.animateNav('action');

    //MOBILE ANIMATION
    if (window.innerWidth < 900) {
      document.getElementById('query-list').style.overflowY = 'scroll';
      document.getElementById('download-nav').style.paddingBottom = '0%';
      document.getElementById('action-nav').style.height = "100px";
      document.getElementById('download-nav').style.backgroundColor =
        'transparent';
        this.timer = setTimeout(function () {
          document.getElementById('actionSection').style.zIndex = "5";
        }, 1500);
      this.resetQueries();
    }
    var arrow = document.getElementById('mobile-arrow');
    arrow.style.transition = '1.5s';
    this.arrowSrc = '../../assets/images/arrow-yellow.svg';
    arrow.classList.remove('rotated-arrow');
    arrow.classList.add('arrow-icon');
    this.currentPage = 3;
  }

  public downloadAni(): void {
    this.navBar.animateNav('down-nav');
    this.navBar.resetNav('act-nav');
    document.getElementById('actionSection').style.zIndex = "2";
    document.getElementById('footerLinks').style.marginBottom = '3%';
    document.getElementById('downloadSection').style.transition = '1.5s';
    document.getElementById('downloadSection').style.marginTop = '0vh';
    document.getElementById('dwn-container').style.marginTop = '0%';
    document.getElementById('action-nav').style.transition = "1.35s"
    document.getElementById('action-nav').style.height = '0%';


    this.navBar.resetNav('action');
    this.navBar.animateNav('download');


    //MOBILE ANIMATION
    if (window.innerWidth < 900) {
      document.getElementById('query-list').style.overflowY = 'hidden';
      document.getElementById('action-nav').style.transition= '2s';
      document.getElementById('action-nav').style.height = '0%';
      document.getElementById('download-nav').style.paddingBottom = '8%';
      if (window.innerWidth < 600)
        document.getElementById('download-nav').style.paddingBottom = '15%';
        document.getElementById('download-nav').style.height = '50px';
      this.timer = setTimeout(() => {
        document.getElementById('download-nav').style.transition = '0s';
        document.getElementById('download-nav').style.backgroundColor =
          '#fedc48';
      }, 1500);
    }

    this.arrowSrc = '../../assets/images/arrow.svg';
    var arrow = document.getElementById('mobile-arrow');
    arrow.style.transition = '1.5s';

    arrow.classList.remove('arrow-icon');
    arrow.classList.add('rotated-arrow');
    this.currentPage = 4;
  }

  public downloadScroll() {
    if (this.currentPage == 4) {
      var download = document.getElementById('main-download');
      if (download.scrollTop <= 0) {
        this.reverseAnimation();
        return;
      }
    } else { return; }
  }

  public resetQueries() {
    var queries = document.getElementsByName('query');
    for (let i = 1; i < queries.length; i++) {
      queries[i].firstElementChild.firstElementChild.removeAttribute('style');
    }
    document.getElementById('query-list').scrollTop = 1;
  }
}
