import {
  Component,
  ViewChild,
  AfterViewInit,
  ɵCompiler_compileModuleSync__POST_R3__,
  ReflectiveInjector,
} from '@angular/core';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-query-list',
  templateUrl: './query-list.component.html',
  styleUrls: ['./query-list.component.css'],
})
export class QueryListComponent implements AfterViewInit {
  public queryList: Array<string> = [
    'SECTION 60 LOCKDOWN',
    'FOUR TIMES MORE LIKELY',
    'APRIL 22 ELTHAM',
    'GO HOME VANS',
    '72 VICTIMS',
    'SLAVERY TAX',
    'INSPECTOR EHIKIOYA',
    'GANGS MATRIX',
    'DARRELL AND DARREN',
    'ESN SCHOOLS UK',
    'LESS THAN 1% OF PROFESSORS',
    'REFERENDUM SPIKE',
    'BRITAIN RACIAL WEALTH COVID',
    '21.7% PAY GAP',
    'HEALTH BIAS UK',
  ];
  public scroll: number = 1;
  public scrollTop: number = 0;
  public query: number = 0;
  public scrollBool: boolean = false;
  @ViewChild('queries') queries;
  constructor(public scrollServ: ScrollService) {}

  ngAfterViewInit(): void {
    if (window.innerWidth > 900) {
      var query = this.queries.nativeElement.childNodes[0].firstChild;
      query.firstChild.style.backgroundColor = '#fedc48';
      query.firstChild.style.color = 'black';
      query.firstChild.childNodes[1].style.backgroundImage =
        'url(../../assets/images/search.svg)';
      return;
    } else {
      var query = this.queries.nativeElement.childNodes[1].firstChild;
      query.firstChild.style.backgroundColor = 'black';
      query.firstChild.style.color = '#fedc48';
      query.firstChild.childNodes[1].style.backgroundImage =
        'url(../../assets/images/Go.svg)';
    }
  }

  public scrollManager(el): void {
    if (el.scrollTop == 1) this.scroll = 1;
    if (el.scrollTop >= this.scrollTop) {
      this.checkInView(el);
      this.scrollTop = el.scrollTop;
    } else {
      this.scrollUp(el);
      this.scrollTop = el.scrollTop;
    }
  }

  public checkInView(el) {
    if (this.scroll >= 15) {
      this.scrollServ.scrollAnimation();
      this.scroll = 1;
      return;
    }
    if (this.scroll > 1)
      var prev1 = el.childNodes[this.scroll - 1].firstChild.childNodes[0];
    if (this.scroll > 2)
      var prev2 = el.childNodes[this.scroll - 2].firstChild.childNodes[0];
    if (this.scroll > 3)
      var prev3 = el.childNodes[this.scroll - 3].firstChild.childNodes[0];
    var query = el.childNodes[this.scroll].firstChild.childNodes[0];
    var active = el.childNodes[this.scroll + 1].firstChild.childNodes[0];
    var rect = query.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    active.style.backgroundColor = 'black';
    active.style.color = '#fedc48';
    active.childNodes[1].style.backgroundImage =
      'url(../../assets/images/Go.svg)';

    var isVisible =
      elemTop < window.innerHeight && elemBottom >= window.innerWidth / 1.6;

      if (window.innerWidth < 600) {
        var isVisible =
        elemTop < window.innerHeight && elemBottom >= window.innerWidth / 1.2;
      }

    if (isVisible) {
      if (query.firstChild != null) {
        query.style.opacity = '0.2';
        query.style.backgroundColor = '#fedc48';
        query.style.color = 'black';
        query.childNodes[1].style.backgroundImage =
          'url(../../assets/images/search.svg)';
      }

      //decrease opacity
      if (prev1 != null) prev1.style.opacity = '0.1';
      if (prev2 != null) prev2.style.opacity = '0.05';
      if (prev3 != null) prev3.style.opacity = '0';
    } else {
      this.scroll++;
    }
  }

  public scrollUp(el): void {
    var list = document.getElementById('query-list');
    if (list.scrollTop <= 0) {
      this.scroll = 1;
      this.scrollServ.navAction(2);
    }


    if (this.scroll > 1)
      var next1 = el.childNodes[this.scroll - 1].firstChild.childNodes[0];
    if (this.scroll > 2)
      var next2 = el.childNodes[this.scroll - 2].firstChild.childNodes[0];

    var query = el.childNodes[this.scroll].firstChild.childNodes[0];
    var active = el.childNodes[this.scroll + 1].firstChild.childNodes[0];
    var rect = query.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    active.style.opacity = '1';
    active.style.backgroundColor = '#fedc48';
    active.style.color = 'black';
    active.childNodes[1].style.backgroundImage =
      'url(../../assets/images/search.svg)';

    query.style.backgroundColor = 'black';
    query.style.opacity = '1';
    query.style.color = '#fedc48';
    query.childNodes[1].style.backgroundImage =
      'url(../../assets/images/Go.svg)';

    if (next1 != null) next1.style.opacity = '0.1';
    if (next2 != null) next2.style.opacity = '0.05';

    var isVisible =
      elemTop < window.innerHeight && elemBottom >= window.innerWidth / 1.6;

    if (window.innerWidth < 600) {
    var isVisible =
      elemTop < window.innerHeight  && elemBottom >= window.innerWidth;
    }

    if (isVisible) {
      if (this.scroll == 1) return;
      this.scroll--;
    }
  }
}

