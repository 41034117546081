<div (window:resize)="this.resize()" id="manifesto" class="manifesto-container">
  <div id="mani-left">
    <p>
      DURING THREE MONTHS OF LOCKDOWN, THE MET CARRIED OUT 22,000 SEARCHES ON
      YOUNG BLACK MEN. MORE THAN 80% OF THESE LED TO NO FURTHER ACTION.
    </p>
  </div>
  <div class="mani-right" id="mani-right">
    <p>
      SYSTEMIC RACISM IN THE UK GOES FAR BEYOND POLICING. IT PERMEATES EVERY
      LEVEL OF OUR SOCIETY AND OUR HISTORY. FROM OUR ACTIVE PARTICIPATION IN THE
      SLAVE TRADE TO THE DISPROPORTIONATE EFFECTS OF THE PANDEMIC ON PEOPLE OF
      COLOUR.
    </p>
    <p id="sub-padding">
      IT'S NEVER BEEN EASIER TO EDUCATE YOURSELF.<br>STOP, SEARCH AND DO THE
      WORK.
    </p>
  </div>
</div>
