<div
  id="overlay"
  (window:touchstart)="this.scrollServ.handleTouchStart($event)"
  (window:touchmove)="this.scrollServ.handleTouchMove($event)"
  (window:wheel)="this.scrollServ.scrollAction($event)"
  (window:resize)="this.stopAnimation()"
></div>

<div id="topSection" class="top-section">
  <app-nav-bar id="top-nav-bar"></app-nav-bar>
  <div
    id="main"
    class="main-content"
  >
    <app-main-title></app-main-title>
    <app-static-title></app-static-title>
    <app-manifesto></app-manifesto>
  </div>
</div>

<app-nav-bar class="action-nav" id="action-nav" action="true"></app-nav-bar>

<div id="actionSection" class="action-section">
  <div
    id="main"
    class="main-content"
  >
    <app-static-title action="true"></app-static-title>
    <app-query-list></app-query-list>
  </div>
</div>

<app-nav-bar class="download-nav" id="download-nav"></app-nav-bar>
<div
  id="downloadSection"
  class="download-section"
>

  <div
    id="main-download"
    class="main-content"
    (touch)="this.scrollServ.downloadScroll()"
    (scroll)="this.scrollServ.downloadScroll()"
    (wheel)="this.scrollServ.scrollAction($event)"
  >
    <app-gallery></app-gallery>
    <app-download></app-download>
  </div>
</div>

<img
  #arrow
  id="mobile-arrow"
  (click)="this.scrollDown()"
  alt="Scroll Down"
  class="arrow-icon"
  [attr.src]="this.scrollServ.arrowSrc"
/>
