<div class="gallery-container">
  <div class="gallery-nav">
    <p (click)="this.switchImage('left')" class="gallery-text">PREVIOUS</p>
    <p (click)="this.switchImage('right')" class="gallery-text">NEXT</p>
  </div>

  <div (click)="this.switchImage('left')" class="left-side-gal"></div>
  <div (click)="this.switchImage('right')" class="right-side-gal"></div>
  <div #gallery class="gallery-images">
    <div
      class="gallery-img"
      alt="Stop and Search Poster"
      style="background-image: url(../../assets/images/poster-placement.jpg)"
    ></div>
    <div
      class="gallery-img gal-mid"
      alt="Stop and Search Poster"
      style="background-image: url(../../assets/images/Shoreditch-Art-Wall.jpg)"
    ></div>
    <div
      class="gallery-img gal-right"
      alt="Stop and Search Poster"
      style="background-image: url(../../assets/images/Window-Placement.jpg)"
    ></div>
  </div>
</div>
