import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MainTitleComponent } from './main-title/main-title.component';
import { StaticTitleComponent } from './static-title/static-title.component';
import { ManifestoComponent } from './manifesto/manifesto.component';
import { QueryComponent } from './query/query.component';
import { QueryListComponent } from './query-list/query-list.component';
import { GalleryComponent } from './gallery/gallery.component';
import { DownloadComponent } from './download/download.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { RouteComponent } from './route/route.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    StaticTitleComponent,
    MainTitleComponent,
    ManifestoComponent,
    QueryComponent,
    QueryListComponent,
    GalleryComponent,
    DownloadComponent,
    NotFoundComponent,
    RouteComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      { path: '', component: RouteComponent },
      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '404' },
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
