import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements AfterViewInit {
  public currentPage = '';
  @ViewChild('nav') nav;
  @ViewChild('arrow') arrow;
  @Input() action: boolean;

  public constructor(public scrollServ: ScrollService) {}

  ngAfterViewInit(): void {
    if (this.action) {
      this.nav.nativeElement.style.marginTop = '0';
      this.nav.nativeElement.style.color = '#fedc48';
      this.arrow.nativeElement.src = '../../assets/images/arrow-yellow.svg';
    }
  }

  public animateNav(name) {
    var nav = document.getElementsByName(name);
    for (var i = 0; i < nav.length; i++) {
      nav[i].classList.remove('nav-item');
      nav[i].classList.add('toPage');
    }
  }

  public resetNav(name) {
    var nav = document.getElementsByName(name);
    for (var i = 0; i < nav.length; i++) {
      nav[i].classList.remove('toPage');
      nav[i].classList.add('nav-item');
    }
  }

  public resize() {
    var mani = document.getElementsByName('manifesto');
    var act = document.getElementsByName('action');
    var down = document.getElementsByName('download');

    for (var i = 0; i < mani.length; i++) {
      mani[i].style.transition = '0s';
      act[i].style.transition = '0s';
      down[i].style.transition = '0s';
    }

    setTimeout(() => {
      for (var i = 0; i < mani.length; i++) {
        mani[i].style.transition = '1.5s';
        act[i].style.transition = '1.5s';
        down[i].style.transition = '1.5s';
      }
    }, 400);
  }
}
